import Button from "@/components/Button";
import React from "react";

type Props = {
  statusCode?: number | string;
  statusText?: string;
  message?: string;
};

const NotFoundPage: React.FC<Props> = ({
  statusCode = 404,
  statusText = "Page not found",
  message = "Please check the URL in the address bar and try again.",
}) => {
  return (
    <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-[64px] leading-none font-bold text-purple-500">
            {statusCode}
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6 max-w-md">
              <h1 className="text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
                {statusText}
              </h1>
              <p className="mt-4 text-base text-gray-600">{message}</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Button
                text="Go back home"
                link={process.env.NEXT_PUBLIC_DASHBOARD_URL}
                className="!px-4 !py-2 !text-sm"
              />
              <Button
                text="Help Center"
                link="https://help.tapt.io/en"
                className="!px-4 !py-2 !text-sm"
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default NotFoundPage;
