import { CSSProperties } from 'react';

export enum ButtonKind {
  PRIMARY = 'primary',
  WHITE = 'white',
  OUTLINE = 'outline',
  RED = 'red',
  CIRCULAR = 'circular',
  TEXT = 'text',
  ICON = 'icon',
}

export enum ButtonSize {
  SMALL = 'small',
  LARGE = 'large',
  RESPONSIVE = 'responsive',
}

export enum ButtonIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface ButtonCMSProps {
  text: string;
  link?: string;
  kind?: ButtonKind;
  size?: ButtonSize;
  external?: boolean;
}
export default interface Button extends ButtonCMSProps {
  className?: string;
  disabled?: boolean;
  externalBlankTarget?: boolean;
  fullWidth?: boolean;
  icon?: JSX.Element;
  iconPos?: ButtonIconPosition;
  iconClassName?: string;
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  style?: CSSProperties;
  cosmetic?: boolean;
  download?: boolean | string;
}
