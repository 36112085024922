import React from 'react';
import Link from 'next/link';
import IButton, {
  ButtonIconPosition,
  ButtonKind,
  ButtonSize,
} from '@/types/Button';
import Spinner from '@/components/icons/Spinner';
import classNames from '@/helpers/classNames';

export default function Button(props: IButton): JSX.Element {
  const {
    text,
    className = '',
    disabled = false,
    external = false,
    externalBlankTarget = true,
    fullWidth = false,
    link,
    icon,
    iconPos = ButtonIconPosition.LEFT,
    iconClassName = '',
    kind = ButtonKind.PRIMARY,
    loading = false,
    onClick,
    size = ButtonSize.LARGE,
    cosmetic = false,
    download = false,
  } = props;
  const baseCX =
    'appearance-none font-poppins rounded-md font-medium transform transition-all transition duration-300 flex shrink-0 items-center justify-center border';
  const baseHoverCX = '';

  const classCX: {
    size: { [key: string]: string };
    kind: { [key: string]: { [key: string]: string } };
  } = {
    size: {
      small: 'text-sm leading-4 py-2.5 px-4',
      large: 'text-base leading-5 py-3.5 px-6',
      responsive:
        'text-sm leading-4 py-2.5 px-4 md:text-base md:leading-5 md:py-3.5 md:px-6',
    },
    kind: {
      primary: {
        base: 'text-white bg-purple-500 border-purple-500',
        focus:
          'focus:outline-none focus:ring-purple-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:bg-grey-200 disabled:text-grey-500 disabled:border-grey-200',
        hover:
          'hover:text-white hover:bg-purple-700 hover:border-purple-700 hover:no-underline',
      },
      white: {
        base: 'text-grey-700 bg-white border-grey-300',
        focus:
          'focus:outline-none focus:ring-purple-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:bg-white disabled:text-grey-400 disabled:border-grey-200',
        hover: 'hover:bg-grey-100 hover:text-grey-700 hover:no-underline',
      },
      outline: {
        base: 'text-white bg-transparent border-grey-300',
        focus:
          'focus:outline-none focus:ring-purple-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:bg-white disabled:text-grey-400 disabled:border-grey-200',
        hover: 'hover:bg-grey-100 hover:text-grey-700 hover:no-underline',
      },
      red: {
        base: 'text-white bg-red-500 border-red-500',
        focus:
          'focus:outline-none focus:ring-red-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:bg-grey-200 disabled:text-grey-500 disabled:border-grey-200',
        hover:
          'hover:text-white hover:bg-red-700 hover:border-red-700 hover:no-underline',
      },
      text: {
        base: 'border-transparent outline-none py-0 px-0 font-medium text-purple-500',
        focus: 'focus:outline-none',
        disabled: 'disabled:font-normal disabled:text-grey-500',
        hover: 'hover:text-purple-700 hover:no-underline',
      },
      circular: {
        base: 'rounded-[100px] bg-purple-500 border-0 text-white w-auto font-poppins',
        focus:
          'focus:outline-none focus:ring-purple-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:bg-grey-200 disabled:text-grey-500 disabled:border-grey-200',
        hover:
          'hover:text-white hover:bg-purple-700 hover:border-purple-700 hover:no-underline',
      },

      icon: {
        base: 'rounded-[100%] p-0 bg-purple-500 border-0 text-white w-10 h-10',
        focus:
          'focus:outline-none focus:ring-purple-700 focus:shadow-sm focus:ring-2 focus:ring-offset-2',
        disabled:
          'disabled:font-normal disabled:bg-grey-200 disabled:text-grey-500 disabled:border-grey-200',
        hover:
          'hover:text-white hover:bg-purple-700 hover:border-purple-700 hover:no-underline',
      },
    },
  };

  const buttonCX = classNames(
    className,
    baseCX,
    baseHoverCX,
    fullWidth ? 'w-full' : 'w-max',
    classCX.size[`${size}`],
    classCX.kind[`${kind}`].base,
    classCX.kind[`${kind}`].disabled,
    classCX.kind[`${kind}`].focus,
    classCX.kind[`${kind}`].hover,
  );

  const iconCX = classNames(iconClassName, 'h-5 w-5');

  if (cosmetic) {
    return (
      <span className={buttonCX}>
        {icon && iconPos === ButtonIconPosition.LEFT && (
          <div className={classNames(text !== '' ? 'mr-2' : '')}>
            {React.cloneElement(icon, { className: iconCX })}
          </div>
        )}
        {text}
        {loading && <Spinner />}
        {icon && iconPos === ButtonIconPosition.RIGHT && (
          <div className={classNames(text !== '' ? 'ml-2' : '')}>
            {React.cloneElement(icon, { className: iconCX })}
          </div>
        )}
      </span>
    );
  }

  if (link && !onClick) {
    if (external) {
      const target = externalBlankTarget ? '_blank' : '_self';
      return (
        <a
          className={buttonCX}
          href={link}
          rel="noreferrer noopener nofollow"
          target={target}
          download={download}
        >
          {icon && iconPos === ButtonIconPosition.LEFT && (
            <div className={classNames(text !== '' ? 'mr-2' : '')}>
              {React.cloneElement(icon, { className: iconCX })}
            </div>
          )}
          {text}
          {loading && <Spinner />}
          {icon && iconPos === ButtonIconPosition.RIGHT && (
            <div className={classNames(text !== '' ? 'ml-2' : '')}>
              {React.cloneElement(icon, { className: iconCX })}
            </div>
          )}
        </a>
      );
    }

    return (
      <Link href={link} passHref prefetch={false}>
        <a className={buttonCX} href={link}>
          {icon && iconPos === ButtonIconPosition.LEFT && (
            <div className={classNames(text !== '' ? 'mr-2' : '')}>
              {React.cloneElement(icon, { className: iconCX })}
            </div>
          )}
          {text}
          {loading && <Spinner />}
          {icon && iconPos === ButtonIconPosition.RIGHT && (
            <div className={classNames(text !== '' ? 'ml-2' : '')}>
              {React.cloneElement(icon, { className: iconCX })}
            </div>
          )}
        </a>
      </Link>
    );
  }

  return (
    <button
      className={buttonCX}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {icon && iconPos === ButtonIconPosition.LEFT && (
        <div className={classNames(text !== '' ? 'mr-2' : '')}>
          {React.cloneElement(icon, { className: iconCX })}
        </div>
      )}
      {text}
      {loading && <Spinner className="ml-2" />}
      {icon && iconPos === ButtonIconPosition.RIGHT && (
        <div className={classNames(text !== '' ? 'ml-2' : '')}>
          {React.cloneElement(icon, { className: iconCX })}
        </div>
      )}
    </button>
  );
}
